// import { Link } from "react-router-dom";

// const Footer = () => {

//     return (


//         <footer className="mt-32 bg-secondary font-primary z-100">
//             <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
//                 <div className="md:flex md:justify-between">
//                     <div className="mb-6 md:mb-0">
//                         <Link to="/" className="flex items-center">
//                             <img src="https://res.cloudinary.com/dm56xy1oj/image/upload/v1725991678/Navbar_Hexa_Only_nvn1le.svg" className="h-12 w-12" alt="Accountistry Logo" />
//                             <span className="self-center text-2xl font-medium whitespace-nowrap text-primary ml-1">Accountistry</span>
//                         </Link>
//                     </div>
//                     <div className="ml-[40%] grid sm:gap-3 sm:grid-cols-3">
//                         <div>
//                             <h2 className="mb-6 text-sm font-semibold text-primary uppercase text-primary">Resources</h2>
//                             <ul className=" text-primary font-medium">
//                                 <li className="mb-4">
//                                     <Link to="https://www.fasb.org/" className="hover:underline">FASB</Link>
//                                 </li>
//                                 <li className="mb-4">
//                                     <Link to="https://www.aicpa-cima.com/home" className="hover:underline">AICPA</Link>
//                                 </li>
//                                 <li className="mb-4">
//                                     <Link to="https://pcaobus.org/" className="hover:underline">PCAOB</Link>
//                                 </li>
//                                 <li className="mb-4">
//                                     <Link to="https://www.irs.gov/" className="hover:underline">IRS</Link>
//                                 </li>
                                
//                             </ul>
//                         </div>
//                         <div>
//                             <h2 className="mb-6 text-sm font-semibold uppercase text-primary">Follow us</h2>
//                             <ul className="text-primary font-medium">
//                                 <li className="mb-4">
//                                     <Link to="https://www.facebook.com/profile.php?id=61563816361606" className="hover:underline ">Facebook</Link>
//                                 </li>
//                                 <li className="mb-4">
//                                     <Link to="https://www.yelp.com/biz/accountistry-lake-elsinore-2" className="hover:underline ">Yelp</Link>
//                                 </li>
                                
//                             </ul>
//                         </div>
//                         <div>
//                             <h2 className="mb-6 text-sm font-semibold uppercase text-primary">Legal</h2>
//                             <ul className="text-primary font-medium">
//                                 <li className="mb-4">
//                                     <Link to="#" className="hover:underline">Privacy Policy</Link>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//                 <hr className="my-4 border-primary sm:mx-auto lg:my-4" />
//                 <div className="sm:flex sm:items-center sm:justify-between">
//                     <span className="text-sm  sm:text-center text-primary">© 2024 <Link to="https://flowbite.com/" className="hover:underline">Accountistry™</Link>. All Rights Reserved.
//                     </span>
                    
//                 </div>
//             </div>
//         </footer>

//     );

// };

// export default Footer


import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="mt-32 bg-secondary font-primary z-100">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          {/* Logo Section */}
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center">
              <img
                src="https://res.cloudinary.com/dm56xy1oj/image/upload/v1725991678/Navbar_Hexa_Only_nvn1le.svg"
                className="h-12 w-12"
                alt="Accountistry Logo"
              />
              <span className="self-center text-2xl font-medium whitespace-nowrap text-primary ml-1">
                Accountistry
              </span>
            </Link>
          </div>

          {/* Footer Links */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-3 sm:mt-0">
            {/* Resources */}
            <div>
              <h2 className="mb-6 text-sm font-semibold text-primary uppercase">
                Resources
              </h2>
              <ul className="text-primary font-medium">
                <li className="mb-4">
                  <Link
                    to="https://www.fasb.org/"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FASB
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="https://www.aicpa-cima.com/home"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AICPA
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="https://pcaobus.org/"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PCAOB
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="https://www.irs.gov/"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IRS
                  </Link>
                </li>
              </ul>
            </div>

            {/* Follow Us */}
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-primary">
                Follow us
              </h2>
              <ul className="text-primary font-medium">
                <li className="mb-4">
                  <Link
                    to="https://www.facebook.com/profile.php?id=61563816361606"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="https://www.yelp.com/biz/accountistry-lake-elsinore-2"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Yelp
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal */}
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-primary">
                Legal
              </h2>
              <ul className="text-primary font-medium">
                <li className="mb-4">
                  <Link to="#" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr className="my-4 border-primary sm:mx-auto lg:my-4" />

        {/* Footer Bottom Section */}
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-primary sm:text-center">
            © 2024{" "}
            <Link
              to="/"
              className="hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Accountistry™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
