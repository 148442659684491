import Statistic from "../components/Statistic";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TestimonialSlider from "../components/TestimonialSlider";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="relative min-h-screen w-screen bg-bgnew bg-cover bg-center bg-repeat">
      <div className="absolute z-[-1] inset-0 animate-pulse bg-gradient-to-r from-transparent to-primary opacity-30"></div>

      <Navbar />

      {/* Hero Section */}
      <div className="relative mt-[-3%] flex flex-col md:flex-row items-center justify-center">
        <div className="rounded-xl flex flex-col md:flex-row items-center justify-center space-y-5 md:space-y-0 md:space-x-10 w-[85%] px-5 md:px-10">
          <div className="md:h-[60%] md:w-[60%] mt-20 md:mt-36">
            <h1 className="text-secondary md:text-left text-center font-bold font-primary text-3xl md:text-4xl animate-slideInFromLeft">
              Welcome to Accountistry!
            </h1>
            <p className="text-secondary font-primary text-lg md:text-2xl mt-3 animate-slideInFromLeft text-center md:text-justify">
              Looking to cut costs without cutting corners? Accountistry has the perfect solution!
              We help you outsource your accounting overseas, where top-notch talent meets budget-friendly pricing.
              Think of us as your financial matchmakers, connecting you with expert accountants across the globe who keep
              your books in check while you keep more cash in your pocket. Let us take the stress out of accounting so you
              can focus on what you do best—growing your business!<br /><br />
            </p>
          </div>
          <img
            src="https://res.cloudinary.com/dm56xy1oj/image/upload/v1725990940/Home_krpcbs.svg"
            className="w-[80%] md:w-[60%] h-[80%] md:h-[60%] xl:mt-10 z-100 rounded-xl md:mt-44 animate-slideInFromRight"
            alt="Background"
          />
        </div>
      </div>

      {/* Statistics Section */}
      <div className="flex mt-[-9%] md:mt-[-5%] items-center justify-center min-h-screen animate-fadeInSlideUp">
        <div className="w-[90%] md:w-[70%] rounded-xl p-5">
          <Statistic />
        </div>
      </div>

      {/* Testimonial Slider */}
      <TestimonialSlider />

      {/* Contact Section */}
      <div className="flex justify-center">
        <div className="w-[85%] px-5 md:px-10">
          <p className="mt-10 mb-5 font-primary text-lg md:text-xl text-secondary">
            Ready to learn more?
          </p>
          <p className="mt-5 mb-16 font-primary text-lg md:text-xl text-secondary">
            Send us a note on what your accounting needs are at
            <a href="mailto:info@accountistryllp.com" className="underline text-black hover:text-purple-400"> info@accountistryllp.com</a>
            <br />
            or call <a href="tel:+19512231881" className="underline text-black hover:text-purple-400">+1 951 223-1881</a> for a quick conversation.
          </p>

          <Link
            to="/contactus"
            className="rounded-md bg-primary font-primary px-3 py-1.5 font-dm text-md font-semibold text-secondary shadow-md shadow-secondary/50 transition-transform duration-200 ease-in-out hover:scale-[1.10] hover:font-bold hover:bg-secondary hover:text-primary"
          >
            Contact Us
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
