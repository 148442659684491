import { Link } from "react-router-dom";
import { useState } from "react";
import { HiMenuAlt3, HiX } from "react-icons/hi"; // Import menu icons

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <header className="sticky top-0 z-50 bg-secondary backdrop-blur-lg">
            <nav className="mx-auto flex max-w-7xl items-center px-6 transition-all duration-200 ease-in-out py-4">
                {/* Logo and Title */}
                <div className="relative flex items-center">
                    <Link to="/">
                        <div className="w-10 h-10 sm:w-12 sm:h-12 overflow-hidden flex items-center justify-center">
                            <img
                                className="w-full h-full object-contain"
                                src="https://res.cloudinary.com/dm56xy1oj/image/upload/v1725991678/Navbar_Hexa_Only_nvn1le.svg"
                                loading="lazy"
                                style={{ color: "transparent" }}
                                alt="Hexa Only Logo"
                            />
                        </div>
                    </Link>
                    <Link to="/" className="ml-2 text-primary text-lg sm:text-xl font-primary">
                        Accountistry
                    </Link>
                </div>

                <div className="flex-grow"></div>

                {/* Desktop Links */}
                <div className="hidden items-center justify-center gap-6 md:flex">
                    <ul className="flex items-center justify-center gap-6">
                        {["Home", "About Us", "Services", "Industries", "Careers"].map((item, index) => (
                            <li key={index} className="font-dm text-md font-medium text-primary font-primary hover:text-black hover:scale-110 hover:font-bold transition transform">
                                <Link to={item === "Home" ? "/" : `/${item.toLowerCase().replace(/\s+/g, '-')}`}>
                                    {item}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link
                        to="/contactus"
                        className="rounded-md bg-gradient-to-br from-primary to-secondary font-primary px-3 py-1.5 font-dm text-md font-semibold text-secondary shadow-md shadow-secondary/50 transition-transform duration-200 ease-in-out hover:scale-[1.10] hover:text-black hover:font-bold"
                    >
                        Contact Us
                    </Link>
                </div>

                {/* Mobile Menu Button */}
                <div className="md:hidden">
                    <button
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        className="text-primary focus:outline-none"
                    >
                        {isMobileMenuOpen ? <HiX size={30} /> : <HiMenuAlt3 size={30} />}
                    </button>
                </div>
            </nav>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden bg-secondary py-4">
                    <ul className="flex flex-col items-center gap-4">
                        {["Home", "About Us", "Services", "Industries", "Careers"].map((item, index) => (
                            <li key={index} className="font-dm text-lg font-medium text-primary font-primary hover:text-black hover:font-bold transition-transform">
                                <Link
                                    to={item === "Home" ? "/" : `/${item.toLowerCase().replace(/\s+/g, '-')}`}
                                    onClick={() => setIsMobileMenuOpen(false)}
                                >
                                    {item}
                                </Link>
                            </li>
                        ))}
                        <Link
                            to="/contactus"
                            className="rounded-md bg-gradient-to-br from-primary to-secondary font-primary px-3 py-1.5 font-dm text-md font-semibold text-secondary shadow-md shadow-secondary/50 transition-transform duration-200 ease-in-out hover:scale-[1.10] hover:text-black hover:font-bold"
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            Contact Us
                        </Link>
                    </ul>
                </div>
            )}
        </header>
    );
};

export default Navbar;
